<template>
  <div class="layout-box" :class="{ 'layout-back': !(list.length === 0) }">
    <div class="nav-bar">
      <van-icon name="arrow-left" class="nav-icon" @click="$router.go(-1)" />
      <span>选择门店</span>
    </div>
    <ul class="layout-page" v-if="!(list.length === 0)">
      <li
        class="list-card"
        v-for="(item, key) in list"
        :key="key"
        @click="goToUrl(1, key)"
      >
        <van-icon name="arrow" class="card-icon" />
        <div class="shop">
          <h4>{{ item.shop_name }}</h4>
          <span>{{ item.city_name }} {{ item.address }}</span>
        </div>
        <div class="usable-beans">
          <div>可用公益豆</div>
          <div class="color">{{ toInteger(item.bean_money) }}</div>
        </div>
      </li>
    </ul>
    <div class="blank-page" v-else>
      <div class="img-box">
        <img src="~@/assets/common/no_evaluation_icon_is_available.png" />
        <div class="text">暂无记录…………</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyBenefitList } from "@/api/benefit";

export default {
  name: "my-beans",
  data() {
    return {
      list: [],
      pthType: this.$route.query.pathType,
    };
  },
  mounted() {
    this.getMyBenefitList();
  },
  methods: {
    toInteger(num) {
      // 取整
      let toNum = "";
      num === undefined ? (toNum = 0) : (toNum = num);
      if (num !== undefined) {
        toNum = parseInt(num);
      }
      return toNum;
    },
    async getMyBenefitList() {
      const ret = await getMyBenefitList();
      if (ret.code * 1 == 1) {
        this.list = ret.data;
        console.log(this.list);
      }
    },
    goToUrl(type, data) {
      //页面跳转
      switch (type) {
        case 1:
          //订单详情
          this.$router.push({
            name: "beans-consume",
            query: {
              shopId: this.list[data].shop_id,
              shopName: this.list[data].shop_name,
            },
          });
          break;

        default:
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layout-box {
  position: relative;
  height: 100vh;
  background-color: #fff;
  .img-box {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    width: 230px;
    img {
      display: block;
      width: 100%;
    }
    .text {
      font-size: 18px;
      color: #919090;
    }
  }
}
.layout-back {
  background-color: transparent;
}
.nav-bar {
  position: relative;
  text-align: center;
  padding: 12px 0;
  background-color: #fff;
  font-size: 16px;
  border-bottom: 1px solid rgb(240, 240, 240);
  .nav-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
.layout-page {
  padding: 12px;
  color: #6f6f6f;
  .list-card {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 50px 12px 12px;
    margin-bottom: 12px;
    border-radius: 16px;
    background-color: #fff;
    font-size: 16px;
    .shop {
      width: 200px;
    }
    h4 {
      color: #000;
      margin: 0 0 12px 0;
      font-size: 17px;
      font-weight: 500;
    }
    span {
      display: -webkit-box; /**对象作为伸缩盒子模型展示**/
      -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
      -webkit-line-clamp: 2; /**显示的行数**/
      overflow: hidden; /**隐藏超出的内容**/
      word-break: break-all; /** 英语字母设定问题，它不会换行在代码中加入强制换行 可以解决这个问题**/
    }
    .usable-beans {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .card-icon {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
    }
    .color {
      color: #fd5e0a;
      margin-top: 12px;
    }
  }
}
</style>
